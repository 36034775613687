import React from 'react';

function Header() {

  return (
    <div className="header headers-font-ubuntu sticky-top">
      <div>Local Sports</div>
    </div>
  );

}

export default Header;
